import { Decimal } from "decimal.js";
//逗號分隔，支持小數點
export function numberWithCommas(x, precision = 2) {
    if (!x) {
        return 0;
    }

    var parts = new Decimal(x).toFixed(precision).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");

    //不能用這個 ios會報錯
    //return x ? new Decimal(x).toFixed(precision).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : 0;
}

//超過限制長度變成...
export function cutTail(x, maxlength = 10) {
    return x ? (x.length > maxlength ? x.substr(0, maxlength) + "..." : x) : x;
}

// 代替Promise.allSettled
// 舊小米Browser 至少低於v10.9.8-g都不支援
export function allSettled(promises) {
    // map the promises to return custom response.
    const mappedPromises = promises.map((p) =>
        Promise.resolve(p).then(
            (val) => ({ status: "fulfilled", value: val }),
            (err) => ({ status: "rejected", reason: err })
        )
    );

    // run all the promises once with .all
    return Promise.all(mappedPromises);
}
