import React, { useEffect, useRef, useState } from 'react';
import Router from 'next/router';
import { get, post } from '$ACTIONS/TlcRequest';
import { ApiPort, APISETS, APISET } from '$ACTIONS/TLCAPI';
import Flexbox from '@/Flexbox/';
import Image from 'next/image';
import { lazyLoadImg } from '$ACTIONS/util';
import { isGuest } from '$ACTIONS/helper';
import { SetPiwikData } from '$ACTIONS/piwikData';
import { sendProductGamePiwikEvent4Home } from '$ACTIONS/ProductGamePiwikUtil';
import { Carousel, Menu, Icon, Button, Col, message, Alert, Modal } from 'antd';
import QRCode from 'qrcode-react';
import { menuBarData, menuRightBarData, hoverSecondary, hoverSecondaryDetail } from '$DATA/header.static';
import { connect } from 'react-redux';
import { getGameCategoryListAction, getSmarticoToggleAction } from '$STORE/thunk/gameThunk';
import { GetGameNavStatus } from '$ACTIONS/gameRequest';
import classNames from 'classnames';
import ImageWithFallback from '@/ImageWithFallback/imgLocal';
import { changeTabAction } from "../../store/thunk/promotionThunk";
import { rewardHomeUrl, doFetchPOSTRewardTokenEncry } from "@/base";
let currentPageCasino = 'firstcasino';
let currentPageSlot = 'firstslot';
let currentPageMore = 'firstmore';
let currentPageCasinoIndex = 0;
let currentPageSlotIndex = 0;
let currentPageMoreIndex = 0;

function HoverContent(props, hoverData, detailData, detailPercentage, secondaryMenuBar) {
	let currentIndex = 0;
	const GameSlider = useRef();

	useEffect(() => {
		if (detailData.type === 'casino') {
			GameSlider.current.goTo(currentPageCasinoIndex);
		} else if (detailData.type === 'slotgame') {
			GameSlider.current.goTo(currentPageSlotIndex);
		} else if (detailData.type === 'more') {
			GameSlider.current.goTo(currentPageMoreIndex);
		}
	}, []);

    function onChange(from, to) {
        //console.log(detailData.type);
        let menuLength;
        if (detailData.type === "casino") {
            currentIndex = to;
            currentPageCasinoIndex = to;
            menuLength = secondaryMenuBar.length - 1;
            if (currentIndex === menuLength) {
                currentPageCasino = "lastcasino";
            } else if (currentIndex == 0) {
                currentPageCasino = "firstcasino";
            }
        } else if (detailData.type === "slotgame") {
            currentIndex = to;
            currentPageSlotIndex = to;
            menuLength = secondaryMenuBar.length - 1;
            if (currentIndex === menuLength) {
                currentPageSlot = "lastslot";
            } else if (currentIndex == 0) {
                currentPageSlot = "firstslot";
            }
        } else if (detailData.type === "more") {
            currentIndex = to;
            currentPageMoreIndex = to;
            menuLength = secondaryMenuBar.length - 1;
            if (currentIndex === menuLength) {
                currentPageMore = "lastmore";
            } else if (currentIndex == 0) {
                currentPageMore = "firstmore";
            }
        }
    }

    let headerData = [ //寫死的header code
        {
            dataCategory:"Sportsbook",
            dataSign:"SPORTS BETTING",
            dataTitle:"体育首存红利",
            dataPercent:"50%",
        },
        {
            dataCategory:"ESports",
            dataSign:"E-SPORT",
            dataTitle:"返水无上限",
            dataPercent:"0.8%",
        },
        {
            dataCategory:"InstantGames",
            dataSign:"INSTANT GAME",
            dataTitle:"日返水返还",
            dataPercent:"0.58%",
        },
        {
            dataCategory:"LiveCasino",
            dataSign:"LIVE CASINO",
            dataTitle:"天天享超高返水",
            dataPercent:"0.9%",
        },
        {
            dataCategory:"P2P",
            dataSign:"P2P GAMING",
            dataTitle:"返水无上限",
            dataPercent:"0.8%",
        },
        {
            dataCategory:"Slot",
            dataSign:"SLOT GAMES",
            dataTitle:"日返水返还",
            dataPercent:"0.58%",
        },
        {
            dataCategory:"KenoLottery",
            dataSign:"LOTTERY",
            dataTitle:"日返水返还",
            dataPercent:"0.40%",
        },
    ]

    let currentData = headerData.find(item => item.dataCategory == props.itemdata?.code);

    //console.log("secondaryMenuBar", secondaryMenuBar);
    return (
        <div className="Content-List">
            {detailData.type != "shop" && detailData.type != "more" ? (
                <div className="menu-sign-bar ">
                    <h2>
                        {props.itemdata && props.itemdata.name
                            ? props.itemdata.name
                            : "暂无数据"}
                    </h2>
                    <p className="sign-color">
                        {currentData && currentData.dataSign
                            ? currentData.dataSign
                            : "暂无数据"}
                    </p>
                    <div className="sign-brief">
                        <p>
                            {currentData && currentData.dataTitle
                                ? currentData.dataTitle
                                : "暂无数据"}
                        </p>
                        <p className="sign-color">
                            {currentData && currentData.dataPercent
                                ? currentData.dataPercent
                                : "暂无数据"}
                        </p>
                    </div>
                </div>
            ) : (
                <div className="menu-sign-bar ">
                    <h2>{detailData.chinese}</h2>
                    <p className="sign-color">{detailData.english}</p>
                    <div className="sign-brief">
                        <p>{detailData.promotion}</p>
                        {~detailPercentage ? (
                            <p className="sign-color">
                                {detailData.promotionVal.substr(
                                    0,
                                    detailPercentage
                                )}
                                <span>%</span>
                            </p>
                        ) : (
                            <p className="sign-color">
                                {detailData.promotionVal}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div
                className={`menu-bar ${
                    detailData.type === "casino"
                        ? currentPageCasino
                        : detailData.type === "slotgame"
                        ? currentPageSlot
                        : detailData.type === "more"
                        ? currentPageMore
                        : ""
                }`}
            >
                {/* 游戏左右滑动列表 */}
                <Carousel
                    ref={GameSlider}
                    arrows={true}
                    beforeChange={onChange}
                >
                    {secondaryMenuBar.map((value, index) => {
                        const currWidth =
                            hoverData.length >= 5 ? "100px" : "120px";

                        return (
                            <div key={index} className="menu-bar-pages">
                                {value.map((innerItem, innerIndex) => {
                                    return (
                                        <div
                                            // id={
                                            //     GameHideArray ===
                                            //         "Sportsbook" &&
                                            //     innerItem.code === "OWS"
                                            //         ? "header_shaba_display"
                                            //         : innerItem.category ===
                                            //               "Esports" &&
                                            //           innerItem.code === "OWS"
                                            //         ? "header_shabaEsports_display"
                                            //         : ""
                                            // }
                                            id={
                                                innerItem.category +
                                                "_" +
                                                innerItem.code
                                            }
                                            className={`menu-bar-children`}
                                            key={innerIndex}
                                            onClick={() => {
                                                SetPiwikData(
                                                    innerItem.category,
                                                    innerItem.code,
                                                    innerItem.providerId
                                                );
                                            }}
                                        >
                                            {/* 静态的菜单 */}
                                            {innerItem.type == "static" && (
                                                <div
                                                    className="list-content"
                                                    onClick={() => {
                                                        props.goPages(
                                                            innerItem.provider
                                                        );
                                                    }}
                                                >
                                                    <ImageWithFallback
                                                        src={
                                                            innerItem.providerImageUrl || "/cn/img/logo.svg"
                                                        }
                                                        width={currWidth}
                                                        height={currWidth}
                                                        alt={innerItem.provider}
                                                    />
                                                    <p>
                                                        {innerItem.providerTitle ||
                                                            innerItem.title}
                                                    </p>
                                                </div>
                                            )}
                                            {innerItem.name && (
                                                <OpenGame
                                                    className={classNames({
                                                        "list-content": true,
                                                        HOT: innerItem.isHot, //热门
                                                        NEW: innerItem.isNew, //新游戏
                                                    })}
                                                    itemsData={{
                                                        Type: "HeaderMenu",
                                                        gameName:
                                                            innerItem.name,
                                                        provider:
                                                            innerItem.code,
                                                        gameId: innerItem.providerId,
                                                        imageUrl:
                                                            innerItem.providerHomepageImage || "/cn/img/logo.svg",
                                                        image:
                                                            innerItem.image || "/cn/img/logo.svg",
                                                        gameCatCode:
                                                            innerItem.category,
                                                        OpenGamesListPage:
                                                            innerItem.type ===
                                                            "Category",
                                                        isNew: innerItem.isNew,
                                                        isHot: innerItem.isHot,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Carousel>
            </div>
            {detailData.qrcodeAddress ? (
                <div className="menu-section app-down">
                    <h3>扫码下载APP</h3>
                    <div>
                        <QRCode
                            //logo={'/cn/img/download/qrlogo.jpg'}
                            size={80}
                            key={global.downloadLinks}
                            value={global.downloadLinks}
                            // logoWidth={30}
                            // logoHeight={30}
                        />
                    </div>
                    {/* <p className="app-down-address">{global.downloadLinks}</p> */}
                    <a
                        className="ant-btn ant-btn-primary ant-btn-sm app-down-address"
                        target="__blank"
                        href={global.downloadLinks}
                    >
                        立即下载
                    </a>
                </div>
            ) : (
                detailData.gameContent != "" && (
                    <div className="menu-section">
                        <p>{props.itemdata && props.itemdata.description}</p>
                        <Button
                            type="link"
                            ghost
                            onClick={() => {

/*<<<<<<< HEAD
                                if (detailData && detailData.type && detailData.type.toLowerCase().indexOf('slot')===0) {
                                    Pushgtagdata('Slots_TopNav', 'Go to Slots Listing', 'Slots_TopNav_C_Listing')
                                }
=======*/
                                sendProductGamePiwikEvent4Home.run4(detailData.type2024)

                                Router.push(
                                    "/cn/Games" + detailData.gameAddress
                                );
                            }}
                        >
                            了解更多
                            <Icon type="right" />
                            <Icon type="right" />
                        </Button>
                    </div>
                )
            )}
        </div>
    );
}

/* 二级菜单Dom结构 url是跳转路径、图片层级路径 */
function SecondaryDom(props) {
	let secondaryMenuBar = [],
		pageSize = -1;
	//console.log("hoverSecondary", hoverSecondary);

	if (props.index < 7) {
		hoverSecondary[props.index] = props.ProviderData;
	} else {
		hoverSecondary[props.index] = hoverSecondary[props.index];
	}


    let hoverData = hoverSecondary[props.index];
    if (props.index === 0) {
        hoverData = hoverData.filter(data => data.code !== "SB2" && data.code !== "YBS")
    }
    const detailData = hoverSecondaryDetail[props.index];
    const detailPercentage = detailData.promotionVal.indexOf("%");
    if (Array.isArray(hoverData) && hoverData.length && hoverData.length) {
        hoverData.forEach((item, index) => {
            if (index % 5 === 0) {
                pageSize++;
            }
            if (!secondaryMenuBar[pageSize]) {
                secondaryMenuBar[pageSize] = [];
            }

			secondaryMenuBar[pageSize].push(item);
		});

		//console.log("二级菜单", hoverData);
		return (
			<div className={`menu-placeholder-bar ${detailData.type}`} key={props.index + 'Menu'}>
				<div className="menu-placeholder-inner-bar">
					{/* Hover游戏菜单 两侧的文字说明和介绍 */}
					{/* {secondaryMenuBar.map((Item, Index) => {
						return HoverContent(props, hoverData, detailData, detailPercentage, [
							secondaryMenuBar[Index]
						]);
					})} */}
					{HoverContent(props, hoverData, detailData, detailPercentage, secondaryMenuBar)}
				</div>
			</div>
		);
	} else {
		return null;
	}
}

import { getAffiliateReferralCode } from '$ACTIONS/helper';
import OpenGame from '../Games/openGame';

class HeaderMenuBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

			route: '',
			asPath: '/',
			a: 1,

			ProviderData: [],
			hostname: '',
		};
		this.data = [];

		this.goPages = this.goPages.bind(this); // 导航菜单界面跳转
	}
	componentDidMount() {
		this.setState({ route: Router.router.route });

		//this.GetGameID();
		//获取导航菜单数据和导航菜单游戏分类数据
		this.props.getGameCategoriesList();
        this.props.getSmarticoToggle();
		this.setState({
			hostname: window.location.origin
		});
		getAffiliateReferralCode();

		// Header 二级菜单图片延迟加载
		const lazyLoadSecondImg = () => {
			lazyLoadImg('t_menu_wrapper');
		};
		document.getElementById('t_menu_box_1').onmouseenter = lazyLoadSecondImg;
		document.getElementById('t_menu_box_2').onmouseenter = lazyLoadSecondImg;

	}
	componentWillUnmount() {
		document.getElementById('t_menu_box_1').onmouseenter = null;
		document.getElementById('t_menu_box_2').onmouseenter = null;
	}

    //open天王俱乐部链接
    openClubUrl() {

        const openClub = u => window.open(u, `_blank`);

        const IS_LOGIN = !isGuest();
        if (IS_LOGIN) {
            let accessToken = localStorage.getItem('access_token');
            let refreshToken = localStorage.getItem('refresh_token');
            doFetchPOSTRewardTokenEncry(accessToken, refreshToken).then(o=>{
                openClub(o._rewardUrl);
            });
            return;
        }

        // Guest start
        openClub(rewardHomeUrl());
    }

	Opengame(innerItem) {
		let teststatus = !localStorage.getItem('access_token');
		if (innerItem.gameCatCode == 'Lottery') {
			if (teststatus) {
				global.goUserSign('1');
				return;
			}
		}

		if (innerItem) {
			Router.push(
				`/cn/Games/Games/${innerItem.gameCatCode}/lobby?name=${innerItem.providerCode}&type=${innerItem.providerId}`
			);
		}
	}

	goPages(link) {
		if (link === '/Refer') {
			global.goReferFriend();
			// message.info("即将开启，敬请期待！");
			return;
		}

		Router.push('/cn' + link);

		switch (link) {
			case '/Sponsor':
				Pushgtagdata('Sponsor_more_topnav');
				break;
			case '/Refer':
				Pushgtagdata('RAF_more_topnav');
				break;
			case '/Download':
				Pushgtagdata('Downloadapp_more_topnav');
				break;
			case '/Selfexclusion':
				Pushgtagdata('Selfexclusion_topnav');
				break;
			case '/USDTintroduce':
				Pushgtagdata('Topnav', 'Launch', 'USDTinfo_more_topnav');
				break;
			default:
				break;
		}
	}

    navPiwikHandleLeft(code) {
        if (code) {
            sendProductGamePiwikEvent4Home.run2dot1(code)
        }
    }

    navPiwikHandleRight(key) {
        if (key === "promotions") {
            Pushgtagdata(
                'Home_TopNav',
                'Go to Promotion',
                'Home_TopNav_C_Promotion'
            );
        } else if (key === "dailygift") {
            Pushgtagdata(
                'Home_TopNav',
                'Go to Daily Deal',
                'Home_TopNav_C_DailyDeal',
                undefined,
                undefined,
                undefined,
                ""
            );
        } else if (key === "shop8") {
            Pushgtagdata(
                'Home_TopNav',
                'Go to Rewards',
                'Home_TopNav_C_Rewards'
            );
        } else if (key === "alliance") {
            Pushgtagdata(
                'Home_TopNav',
                'Go to Affiliate Page',
                'Home_TopNav_C_Affiliate'
            );
        }
    }

	render() {
		const { GameCategory, GameHide } = this.props;
		console.log(GameHide);
		return (
			<div id="t_menu_wrapper" className="menu-wrapper">
				<Menu
					id="t_menu_box_1"
					className={classNames({
						['ant-col-16 Header-Menu Header-Menu-list']: true,
						['Menu_item_Null']: true
					})}
					mode="horizontal"
					selectable={false}
				>
					{/* 首页 */}
					<Menu.Item className={'Home-Icon'}>
						<a
							onClick={() => {
								Router.push({ pathname: '/cn' });
								Pushgtagdata('Navigation', 'Click', 'Home_TopNav');
							}}
							aria-label="前往首页"
						>
							<i className="tlc-sprite home" />
						</a>
					</Menu.Item>
					{/* 游戏导航菜单 */}
					{GameCategory.length != 0 &&
						GameCategory.map((item, index) => {
							// 新增 category 字段
							const providersWithCategory =
								item.subProviders &&
								item.subProviders.map((provider) => ({
									...provider,
									category: item.code,
									providerId: 0
								}));
							if (item.expression) {
								return;
							}
							let labels = JSON.stringify(item.labels || '') || ''
							if (typeof labels==='string') {
								labels = labels.toUpperCase();
							}
							return (
								<Menu.Item key={item.code}>
									<Flexbox
										alignItems="center"
										onClick={() => {
                                            this.navPiwikHandleLeft(item?.code)
											Router.push({
												pathname: '/cn/Games/' + item.code
											});
										}}
										className={classNames({
											['ant-menu-item-active-list']: this.state.route.indexOf(item.code) != -1,
											['Menu_item']: true
										})}
									>
										{item.name}

										{labels.includes('NEW') ? (
											<div className="SetNew">新</div>
										) : labels.includes('HOT') ? (
											<div className="SetHot">热</div>
										) : null}
										<i className="tlc-sprite" />
									</Flexbox>

									<SecondaryDom
										Opengame={(e) => this.Opengame(e)}
										index={index}
										itemdata={item}
										// 传入已经增加了 category 字段的 subProviders 数组
										ProviderData={providersWithCategory}
									/>
								</Menu.Item>
							);
						})}
					{/* 空的占位 防止偏移 */}
					{GameCategory.length == 0 && [ ...Array(7) ].map((_, index) => <Menu.Item key={index} />)}
				</Menu>
				{/* 左边的菜单 */}
				<Menu
					id="t_menu_box_2"
					className="ant-col-8 Header-Menu Header-Menu-list-Right"
					mode="horizontal"
					selectable={false}
				>
					{menuRightBarData.map((item, idx) => {
						return typeof item.isDown !== 'undefined' ? (
							<Menu.Item 
                                key={item.key + idx} 
                                onMouseEnter={() => {
                                    if (item?.key && item?.key.toLowerCase() === "more") {
                                        Pushgtagdata(
                                            'Home_TopNav',
                                            'Go to More',
                                            'Home_TopNav_C_More'
                                        );
                                    }
                                }}
                            >
								<Flexbox
									alignItems="center"
									className={classNames({
										['ant-menu-item-active-list']:
											this.state.route.indexOf(item.key) != -1 ? 'ant-menu-item-active' : '',
										['Menu_item']: true
									})}
								>
									{item.expression}
									<i className="tlc-sprite" />
								</Flexbox>

								<SecondaryDom
									index={item.isDown}
									hostname={this.state.hostname}
									goPages={this.goPages}
									// ProviderData={item.isDown}
								/>
							</Menu.Item>
						) : (
							<Menu.Item key={item.key + idx}>
								<Flexbox
									alignItems="center"
									className={classNames({
										['ant-menu-item-active-list']:
											this.state.route.indexOf(item.key) != -1 ? 'ant-menu-item-active' : '',
										['Menu_item']: true
									})}
									onClick={() => {
                                        this.navPiwikHandleRight(item?.key && item?.key?.toLowerCase());
										if (item.key == 'Alliance') {
											window.open(this.props.storeAffiliateUrl || 'https://www.h32lucky.com/zh-cn', `_blank`);
										} else if (item.key === "DailyGift") {
                                            Router.push(`/cn/Promotions`)
                                            this.props.changeTab("4");
                                        } 
                                        else if (item.key === "Promotions") {
                                            Router.push(`/cn/Promotions`)
                                            this.props.changeTab("1");
                                            this.props.getPromotionList && this.props.getPromotionList();
                                        } 
                                        else {
											if (item.key == 'Shop8') {
												this.openClubUrl();
												return;
											}
											Router.push({
												pathname: '/cn/' + item.key
											});
										}
									}}
								>
									{item.expression}
								</Flexbox>
							</Menu.Item>
						);
					})}
				</Menu>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return {
		storeAffiliateUrl: state.mainSiteConfig.affiliateUrl,
		GameCategory: state.game.categories,
		GameHideArray: state.game.gamesToFilter
	};
};

const mapDispatchToProps = function(dispatch) {
	return {
        changeTab: (i) => {
            dispatch(changeTabAction(i));
        },
		getGameCategoriesList: () => {
			dispatch(getGameCategoryListAction());
		},
        getSmarticoToggle: () => {
            dispatch(getSmarticoToggleAction())
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuBar);
